<template>
  <div class="container">
    <div class="page-title">订单汇总（通过下面状态筛选图片所选分类）</div>
    <div class="content">
      <div class="content-top">
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 150px;">请选择要查询的日期</div>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="dateArr"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
            </el-date-picker>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 40px;">状态</div>
            <el-select v-model="customerServiceValue" placeholder="请选择" style="width: 120px;">
              <el-option
                v-for="item in customerServiceListOptions"
                :key="item.id"
                :label="item.username"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 70px;">关键字</div>
            <el-input
              style="width: 180px;"
              placeholder="请输入菜名"
              v-model="orderNo"
              clearable>
            </el-input>
          </div>
          <div class="date-box">
            <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
            <el-button type="primary" icon="el-icon-delete" @click="reset">重置筛选</el-button>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <el-table
          :data="tableData"
          stripe
          style="width:100%;flex:1;overflow:auto;"
          v-loading="listLoading"
        >
          <el-table-column
            label="时间"
            width="170">
            <template slot-scope="scope">
              {{scope.row.createTime | rTime}}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderNumber"
            label="订单信息"
            width="450">
            <template slot-scope="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column
            label="状态"
            prop="orderStatusName"
            width="140">
            <template slot-scope="scope">
              <div @click="goSelectImage(scope.row)">
                {{scope.row.status === 1 ? '编辑中' : '已完成'}}
                <span class="el-icon-right" style="color: #409EFF;"></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="planStatusName"
            label="分享链接"
            width="140">
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.orderKey" class="copy" effect="dark" content="点击复制" placement="right">
                <div style="margin-right: 20px;width: 80px;" @click="copyUrl(scope.row.orderKey)">复制链接</div>
              </el-tooltip>
              <span v-else>暂无链接</span>
            </template>
          </el-table-column>
          <el-table-column
            label="下载区"
            prop="planStatusName"
            width="140">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status===2" type="primary" icon="el-icon-download" @click="downLoad(scope.row.downUrl)">下载</el-button>
              <span v-else>编辑中</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 10, 20, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        pickerOptions: {
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '昨天',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                picker.$emit('pick', [start, end])
              }
            },
            {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        orderNo:'', //查询
        orderNumber: '',
        tableData: [],
        options: [{
          value: '选项1',
          label: '张三'
        }, {
          value: '选项2',
          label: '李四'
        }, {
          value: '选项3',
          label: '张二'
        }],
        dateArr: [],
        customerServiceListOptions:[],
        customerServiceValue:'',
        value: '',
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        id: '',
      };
    },
    activated () {
      this.queryOrderList(this.currentPage,this.pageSize);
    },
    methods: {
      goSelectImage(item){
        this.$router.push({ name: "selectImage",query:{id:item.id,orderKey:item.orderKey}});
      },
      downLoad(downUrl){
        const url = window.baseURL + '/images'+ downUrl;
        window.open(url)
      },
      copyUrl(downUrl){
        const url = location.href.replace(location.hash,'')+"#/share-image?orderKey="+String(downUrl);
        let _this = this;
        this.$copyText(url).then(function (e) {
          _this.$message({
            showClose: true,
            message: "复制成功",
            type: "success"
          });
        }, function (e) {
          _this.$message({
            showClose: true,
            message: "复制失败",
            type: "error"
          });
        })
      },
      queryOrderList(currentPage,pageSize){
        console.log('this.dateArr,this.orderNo,this.customerServiceValue',this.dateArr,this.orderNo,this.customerServiceValue)
        this.listLoading = true;
        this.$http.post(`/order/findOrderInfo/${currentPage}/${pageSize}`, {
      }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data.list;
              this.total = res.data.data.total;
              console.log('this.taleData', this.tableData)
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      search(){
         this.currentPage = 1;
         this.queryOrderList(this.currentPage,this.pageSize);
      },
      reset(){
        this.orderNo = '';
        this.customerServiceValue = '';
        this.dateArr = [];
        this.currentPage = 1;
        this.queryOrderList(this.currentPage,this.pageSize);
      },
    },
    watch: {

    },
    filters: {
      rTime(data){
        if(!data) return
        var dt = new Date(data);
        var y = dt.getFullYear();
        var m = (dt.getMonth()+1).toString().padStart(2,"0");
        var d = dt.getDate().toString().padStart(2,"0");
        var h = dt.getHours().toString().padStart(2,"0");
        var mm = dt.getMinutes().toString().padStart(2,"0");
        var s = dt.getSeconds().toString().padStart(2,"0");
        return `${y}-${m}-${d} ${h}:${mm}:${s}`;
      },
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex:1;
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .content-bottom {
      flex: 1;
      overflow: auto;
      margin-bottom: 10px;
    }
  }
  .first-line {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/ .el-tooltip.copy  {
    margin-right: 0!important;
    width: auto!important;
  }
</style>
